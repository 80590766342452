import React, { useEffect,  useState } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { CountryPagePropsType, lang, RawTrip } from '../../entities/queriesTypes'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { useGlobalStateContext } from '../../Context/site'
import translations from '../../entities/translations';
import PageSection from '../../components/organism/HomePageComponent';
import {Card} from '../../components/molecule/Card'
import { buildImageFocalPoint, formatMoney } from '../../utils';
import { countrySubMenu } from '../../utils/subNavigation';
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";

const IndexPage:React.FC<CountryPagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.country,
    subNavigationItem: countrySubMenu })
  const { state } = useGlobalStateContext();
  const buttonTransaltion = translations.globals.buttons;
  const pageID = translations.globals.subMenuTranslation;
  const pageHash = translations.countryMenuID;
  const _tripTranslation = translations.CountryPages.find((item) => item.menu === 'Reisen')!;
  const [showReadMore, setShowReadMore] = useState<boolean>(true)
  const [trips, setTrip] = useState<RawTrip [] | undefined>(undefined)
  const FROM = translations.globals.subMenuTripTranslation.Ab?.[state?.lang as lang];
  const TripsLinks = translations.globals.subMenuTripTranslation;


  useEffect(() => {
    if (page?.trips?.length) {
      const _trips = [...page?.trips]
      if (_trips.length < 3) {
        setTrip(_trips)
      } else {
        _trips.length = 3
        setTrip(_trips)
      }
    }
  }, [page?.trips])
  
  const handleReadMore = () => {
    setTrip(page?.trips)
    setShowReadMore(false)
  }

  return (
    <Layout seoTitle={(page?.metadata?.page_title && page?.metadata?.page_title[state?.lang as lang]) as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : '' },
    ]}>
      <Wrapper className="py-16" id={pageHash?.Reisen?.[state?.lang as lang]!}>
      {page?.tripsIntro?.[state?.lang as lang]
        && <PortableTextRenderer body={page?.tripsIntro?.[state?.lang as lang]} />}
      </Wrapper>

      <PageSection
        readMoreLink={"#"}
        sanityContext={null}
        showReadMore={showReadMore}
        id={``}
        onClick={handleReadMore}
        idTitle={pageID.Reisen[state?.lang as lang]!}
        title={''} >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
         {trips?.map((item, i: number) => <Card
          uniqueRef={item?.['_id']!}
          key={i}
          _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
          imageAlt={(item?.tripImage?.alt && item?.tripImage?.alt[state?.lang as lang]) || ''}
          imageUrl={buildImageFocalPoint(item.tripImage?.image)}
          title={(item?.title && item?.title[state?.lang as lang]) || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="PACKAGES"
          ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug && 
            item?.parentCountry?.slug[state?.lang as lang]?.current}/${_tripTranslation.slug[state?.lang as lang]}/${item?.slug 
              && item?.slug[state?.lang as lang]?.current}#${TripsLinks.Uberblick[state?.lang as lang]}`}
          bodyText={(item?.cardTextShort && item.cardTextShort[state?.lang as lang]) || ''}
          duration={(item?.daysAndNightsShort && item.daysAndNightsShort[state?.lang as lang]) || ''}
          price={`${FROM} ${(item?.priceCategoryFrom && formatMoney(item.priceCategoryFrom, item.priceCategoryCurrency))}` || ''}
          cardSize={'SMALL'}
          /> )}
        </div>
      </PageSection>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryTripsPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      countryImage: _rawCountryImage(resolveReferences: {maxDepth: 2})
      tripsIntro:_rawTripsIntro(resolveReferences: {maxDepth: 4})
      trips: _rawTrips(resolveReferences: {maxDepth: 5})
      metadata: _rawCountryTripsMetadata
    }
  }
`
